.header {
  background-color: #4a7c59;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 15px 20px;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.logo {
  height: 60px;
  width: auto;
  margin-right: 20px;
}

.club-name {
  font-size: 1.2em;
  font-weight: bold;
  color: white;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1100;
}

.hamburger-line {
  width: 30px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

.hamburger.active .hamburger-line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.active .hamburger-line:nth-child(2) {
  opacity: 0;
}

.hamburger.active .hamburger-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

.nav {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link,
.contact-button {
  text-decoration: none;
  color: white;
  font-weight: 500;
  transition: opacity 0.3s ease;
  font-size: 0.9em;
}

.nav-link:hover {
  opacity: 0.8;
}

.contact-button {
  background-color: #2ecc71;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #27ae60;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    background-color: #4a7c59;
    flex-direction: column;
    padding: 80px 40px;
    transition: right 0.3s ease;
    gap: 2rem;
  }

  .nav.nav-open {
    right: 0;
  }

  .nav-link,
  .contact-button {
    font-size: 1.2rem;
    text-align: center;
  }

  .contact-button {
    width: 100%;
    text-align: center;
  }
}
